<template>
  <div>
    <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_01.jpg" width="100%" height="100%">
    <van-tabs v-model="active" sticky swipeable animated offset-top="46px">
      <van-tab title="产品介绍">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_02.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_03.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_04.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_06.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_07.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_08.jpg" width="100%" height="100%">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_09.jpg" width="100%" height="100%">
<!--        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机&#45;&#45;4G/摇头机-4G@3x_10.jpg" width="100%" height="100%">-->
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--wifi/摇头机-wifi@3x_11.jpg" width="100%" height="100%">
      </van-tab>
      <van-tab title="参数规格">
        <img src="../../../public/images/h5_xqy(1)/低功耗摇头机--4G/摇头机-4G@3x_11.jpg" width="100%" height="100%">


      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "低功耗摇头机 4G",
  data() {
    return {
      active: 0,
    };
  },
}
</script>

<style scoped>

</style>